<template>
  <!-- Add new role modal -->
  <b-modal
    id="modal-new-role"
    :visible="isEditRoleModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.update')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('role.form.editHeader')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('role.form.name')"
        label-for="role"
        :invalid-feedback="$t('role.form.nameInvalid')"
      >
        <b-form-input
          id="role"
          v-model="roleName"
          type="text"
          :state="nameState"
          :placeholder="$t('role.form.namePlaceholder')"
          required
        />
      </b-form-group>
      <b-form-group label="Status" label-for="status">
        <div>
          <b-form-checkbox
            id="status"
            :checked="roleState"
            class="custom-control-success"
            name="check-button"
            switch
            @change="updateState"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </form>
    <template #modal-ok>
      <b-button variant="primary" :disabled="isLoading">
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          {{ $t('common.update') }}
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditRoleModalShow', 'data'],
    event: ['update:is-edit-role-modal-show', 'update:data'],
  },
  props: {
    isEditRoleModalShow: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const roleName = ref('')
    const roleState = ref(false)
    const nameState = ref(null)
    const form = ref(null)
    const isLoading = ref(false)
    const toast = useToast()

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    watch(
      () => props.isEditRoleModalShow,
      val => {
        if (val) {
          roleName.value = title(props.data.name, '-')
          roleState.value = props.data.isDeleted
        }
      },
    )

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }
      isLoading.value = true
      // EDIT ETHNIC NAME //
      // eslint-disable-next-line no-underscore-dangle
      store
        .dispatch('master-role/editRole', {
          name: roleName.value,
          id: props.data.id,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-role-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success editing a role',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error editing a role',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // UPDATE STATUS ACTIVE INACTIVE
    const updateState = () => {
      // eslint-disable-next-line no-underscore-dangle
      store
        .dispatch('master-role/updateRoleState', {
          id: props.data.id,
          isActive: !roleState.value,
        })
        .then(() => {
          emit('refetch-data')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating state',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (props.data.role !== roleName.value) {
        handleSubmit()
      } else {
        emit('update:is-edit-role-modal-show', false)
      }
    }

    const resetModal = () => {
      roleName.value = ''
      nameState.value = null
      emit('update:is-edit-role-modal-show', false)
      emit('update:data', null)
    }

    return {
      roleName,
      roleState,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      updateState,
      isLoading,
    }
  },
}
</script>
