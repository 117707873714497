<template>
  <!-- Add new role modal -->
  <b-modal
    id="modal-new-role"
    :visible="isAddNewRoleModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.create')"
    :cancel-title="$t('common.cancel')"
    centered
    size="lg"
    :title="$t('role.form.header')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('role.form.name')"
        label-for="role"
        :invalid-feedback="$t('role.form.nameInvalid')"
      >
        <b-form-input
          id="role"
          v-model="roleName"
          type="text"
          :state="nameState"
          :placeholder="$t('role.form.namePlaceholder')"
          required
        />
      </b-form-group>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="modulesData"
        :tbody-tr-attr="hideAuth"
      >
        <template #cell(module)="row">
          {{ $t(row.value) }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            v-model="data.value"
            :disabled="data.item.disabled"
            @change="toggleValue(data.index, data.field.key)"
          />
        </template>
      </b-table>
    </form>
    <template #modal-ok>
      <b-button variant="primary" :disabled="isLoading">
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          {{ $t('common.create') }}
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BTable,
  BFormCheckbox,
  BSpinner,
  VBModal,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useRoleList from './useRoleList'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BTable,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-role-modal-show',
  },
  props: {
    isAddNewRoleModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const roleName = ref('')
    const nameState = ref(null)
    const form = ref(null)
    const newAbility = ref(null)
    const isLoading = ref(false)
    const toast = useToast()
    const { modulesData, hideAuth, defaultAbility, ability } = useRoleList()

    onMounted(() => {
      modulesData.value = defaultAbility
    })

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      newAbility.value = ability()

      isLoading.value = true

      if (newAbility.value === null) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Whoops!',
            text: 'Please select at least one permission',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      store
        .dispatch('master-role/addRole', {
          role_name: roleName.value,
          ability: newAbility.value,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-role-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success creating new role',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new role',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const resetModal = () => {
      roleName.value = ''
      nameState.value = null
      emit('update:is-add-new-role-modal-show', false)
    }

    const toggleValue = (idx, key) => {
      modulesData.value[idx][key] = !modulesData.value[idx][key]
    }

    return {
      roleName,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,

      modulesData,
      hideAuth,
      toggleValue,
      isLoading,
    }
  },
}
</script>
