<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <role-list-add-new
          v-if="$can('create', 'Role')"
          :is-add-new-role-modal-show.sync="isAddNewRoleModalShow"
          @refetch-data="refetchData"
        />

        <role-list-edit
          v-if="$can('update', 'Role')"
          :is-edit-role-modal-show.sync="isEditRoleModalShow"
          :data.sync="selectedRole"
          @refetch-data="refetchData"
        />

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('common.entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="$can('create', 'Role')"
            variant="primary"
            @click="isAddNewRoleModalShow = true"
          >
            {{ $t('role.addBtn') }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              debounce="500"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search') + '...'"
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="status => status.value"
              class="role-filter-select"
              :placeholder="$t('common.filter.status')"
              label="text"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refRoleListTable"
      :items="fetchRoles"
      responsive
      :fields="translatedTableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      :busy="isRoleLoading"
      show-empty
      :empty-text="$t('message.empty')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      no-sort-reset
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner variant="primary" label="Loading..." />
        </div>
      </template>

      <template #cell(name)="data">
        <span>{{ title(data.value, '-') }}</span>
      </template>

      <!-- Column: Status -->
      <template #cell(isDeleted)="data">
        <template v-if="data.value">
          <b-badge pill variant="light-danger">
            Deleted
          </b-badge>
        </template>
        <template v-else>
          <b-badge pill variant="light-success">
            Active
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="$can('update', 'Role')"
            :id="`role-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openEditRoleModal(data.item)"
          />
          <b-tooltip
            v-if="$can('update', 'Role')"
            :title="$t('role.tooltip')"
            class="cursor-pointer"
            :target="`role-row-${data.item.id}-send-icon`"
            triggers="hover"
            placement="left"
          />

          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :disabled="true" @click="confirmDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.isDeleted"
              :disabled="true"
              @click="confirmRestore(data.item)"
            >
              <feather-icon icon="RotateCwIcon" />
              <span class="align-middle ml-50">{{ $t('common.restore') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{
            $tc('common.showing', dataMeta.of, {
              first: dataMeta.from,
              last: dataMeta.to,
              total: dataMeta.of,
            })
          }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRole"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useRolesList from './useRoleList'
import RoleListAddNew from './RoleListAddNew.vue'
import RoleListEdit from './RoleListEdit.vue'

import roleStoreModule from '../roleStoreModule'
import 'animate.css'

export default {
  components: {
    RoleListAddNew,
    RoleListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`role.tableHeader.${column.key}`),
      }))
    },
  },
  methods: {
    confirmDelete({ _id: id, role_name: name }) {
      this.$swal({
        title: `Delete ${title(name, '-')} ?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__shakeX',
        },
        buttonsStyling: false,
      }).then(async result => {
        try {
          if (result.value) {
            const res = await store.dispatch('master-role/deleteRole', { id })

            if (res.data.code !== 400) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                timer: 2000,
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.refRoleListTable.refresh()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
          }
        } catch (e) {
          this.$swal({
            title: 'Error!',
            text: 'Something went wrong!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  setup() {
    const ROLE_MASTER_STORE_MODULE_NAME = 'master-role'

    // Register module
    if (!store.hasModule(ROLE_MASTER_STORE_MODULE_NAME))
      store.registerModule(ROLE_MASTER_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_MASTER_STORE_MODULE_NAME))
        store.unregisterModule(ROLE_MASTER_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const isAddNewRoleModalShow = ref(false)
    const isEditRoleModalShow = ref(false)
    const selectedRole = ref(null)

    const openEditRoleModal = data => {
      selectedRole.value = data
      isEditRoleModalShow.value = true
    }

    const {
      fetchRoles,
      isRoleLoading,
      refetchData,
      deleteRole,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refRoleListTable,
      currentPage,
      totalRole,
      dataMeta,
    } = useRolesList()

    return {
      fetchRoles,
      isRoleLoading,
      refetchData,
      deleteRole,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      isSortDirDesc,
      tableColumns,
      refRoleListTable,
      totalRole,
      currentPage,
      dataMeta,
      title,

      openEditRoleModal,
      isAddNewRoleModalShow,
      isEditRoleModalShow,
      selectedRole,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.role-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
